<template>
  <div class="benefit-withdraw-log">
    <navbar bg="transparent" fixed />
    <van-list
      v-model="loading"
      class="list-content"
      :finished="finished"
      finished-text="没有更多了"
      @load="fetchList"
    >
      <div v-for="item in resultList" :key="item.id" class="list-item">
        <div class="top-id">
          <div class="id">{{ item.id }}</div>
          <div class="status" :class="`status-${item.withdraw_status}`">
            {{ status[item.withdraw_status] }}
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div class="goods">提现支付宝</div>
            <div class="date">{{ item.cdatetime }}</div>
          </div>
          <div class="right">{{ item.money_withdraw }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { List } from "vant";
import apis from "@/apis/index";
import Navbar from "@/components/h5/Navbar";

const TYPES = {
  first: "first",
  second: "second"
};

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "BenefitWithdrawLog",
  components: {
    "van-list": List,
    Navbar
  },
  data() {
    return {
      TYPES,
      page: 1,
      finished: false,
      loading: false,
      resultList: [],
      status: {
        0: "审核中",
        1: "提现成功",
        2: "提现失败"
      }
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    // this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const {
          data: { user_withdraw_list, max_page }
        } = await apis.userWithdrawIndex({ page: this.page });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...user_withdraw_list];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.benefit-withdraw-log {
  // margin: 20px;
  // padding: 20px;
  // box-sizing: border-box;
  // height: calc(100% - 90px);

  .list-content {
    // padding: 0 20px;
    margin: 30px 40px 40px 40px;
    // padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    // overflow: auto;
    // height: calc(100% - 60px);
    // flex: none;

    .list-item {
      flex: none;
      height: 280px;
      width: 1045px;
      background: #ffffff;
      border-radius: 10px;
      font-size: 12px;
      color: #666666;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 40px;
      box-sizing: border-box;
      margin-bottom: 40px;

      .top-id {
        border-bottom: 1px solid #f0f0f0;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        height: 100px;
        line-height: 100px;
        display: flex;
        justify-content: space-between;

        .status {
          font-size: 40px;
          font-family: Microsoft YaHei;
          font-weight: 400;

          &.status-0 {
            color: #148bfa;
          }

          &.status-1 {
            color: #ff5500;
          }

          &.status-2 {
            color: #1aa97b;
          }
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 182px;

        .left {
          .goods {
            font-size: 44px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .date {
            margin-top: 30px;
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .right {
          font-size: 56px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>
